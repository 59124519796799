import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import WarningIcon from '@material-ui/icons/Warning';
import {
  Typography,
  Card,
  CardHeader,
  CardContent,
  Divider,
  CardActions,
  Button,
} from '@material-ui/core';
import * as API from 'services/api';
import { useSelector, useDispatch } from 'react-redux';
import { appProfileRoleSelector } from 'redux/selectors';
import { ROLE_ADMIN } from 'config';
import { injectIntl, FormattedMessage } from 'react-intl';
import ClientSelect from 'components/Select/ClientSelect';
import ModalWrap from 'components/Modals/ModalWrap';
import { setLoading } from 'redux/actions/app';
import { store } from 'react-notifications-component';

const useStyles = makeStyles(theme => ({
  modal: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    outline: 'none',
    boxShadow: theme.shadows[20],
    width: 700,
    maxHeight: '100%',
    overflowY: 'auto',
    maxWidth: '100%',
  },
  button: {
    marginRight: 20,
    backgroundColor: '#040f40',
    padding: '10px 20px',
    color: '#fff',
    borderRadius: 15,
    '&:hover': {
      backgroundColor: '#00B0EC',
    },
    '&:disabled': {
      backgroundColor: 'rgba(0, 0, 0, 0.12)',
      cursor: 'default',
    },
    display: 'inline-block',
  },
  hide: {
    display: 'none',
  },
  center: {
    textAlign: 'center',
  },
}));

const ImportUsersModal = ({
  addFree,
  visible,
  toggleModal,
  intl: { formatMessage },
}) => {
  const classes = useStyles();
  const role = useSelector(appProfileRoleSelector);
  const dispatch = useDispatch();
  const [file, setFile] = useState({
    file: null,
    type: '',
  });

  useEffect(
    () => {
      if (visible) {
        setFile({
          file: null,
          type: '',
        });
      }
    },
    [visible],
  );

  const onUpload = (file, type) => {
    dispatch(setLoading(true));
    const formData = new FormData();
    formData.append('type', type);
    formData.append('file', file);
    API.importUsers(formData)
      .then(res => {
        dispatch(setLoading(false));
        if (res.code === 200) {
          toggleModal(true)
        } else {
          store.addNotification({
            message: res.message,
            type: 'danger',
            container: 'top-right',
            animationIn: ['animated', 'fadeIn'],
            animationOut: ['animated', 'fadeOut'],
            dismiss: {
              duration: 5000,
              onScreen: true,
            },
          });
        }
      })
      .catch(err => {
        dispatch(setLoading(false));
      });
  };

  const onFileChange = event => {
    const file = event.target.files[0];
    const { name } = file;
    if (String(name).toLowerCase().indexOf('csv') >= 0) {
      setFile({
        type: 'csv',
        file,
      });
    } else {
      setFile({
        type: 'excel',
        file,
      });
    }
  };
  const removeFile = () => {
    setFile({
      file: null,
      type: '',
    });
  };

  const onClose = () => {
    toggleModal();
  };

  return (
    <ModalWrap title={<FormattedMessage id="home.client.import" />} open={visible} closeModal={onClose}>
      <input
        type="file"
        id="users-file"
        accept=".xlsx, .xls, .csv"
        className={classes.hide}
        onChange={onFileChange}
      />
      <React.Fragment>
        {file.file !== null && (
          <Typography component="p">{file.file.name}</Typography>
        )}
        {file.file === null ? (
          <label htmlFor="users-file" className={classes.button}>
            <FormattedMessage id="select_file" />
          </label>
        ) : (
          <Button
            onClick={removeFile}
            variant="contained"
            classes={{ root: classes.button }}
          >
            <FormattedMessage id="remove" />
          </Button>
        )}
        <br />
        <br />
      </React.Fragment>
      <CardActions style={{ justifyContent: "space-around" }}>
        <Button
          variant="contained"
          classes={{ root: classes.button }}
          onClick={() => {
            window.open("https://console-espaceclient.apave.com/doc/client.xlsx", '_blank')
          }}
        >
          <FormattedMessage id="downloadmodel" />
        </Button>

        <Button
          variant="contained"
          classes={{ root: classes.button }}
          disabled={file.file === null}
          onClick={() => {
            onUpload(file.file, file.type);
          }}
        >
          <FormattedMessage id="upload" />
        </Button>
      </CardActions>
    </ModalWrap>
  );
};

ImportUsersModal.propTypes = {
  addFree: PropTypes.bool,
  toggleModal: PropTypes.func.isRequired,
  visible: PropTypes.bool.isRequired,
  onUpload: PropTypes.func.isRequired,
  intl: PropTypes.shape({
    formatMessage: PropTypes.func.isRequired,
  }),
};

ImportUsersModal.defaultProps = {
  addFree: false,
};

export default injectIntl(ImportUsersModal);
