
import React, { } from 'react';
import ModalWrap from 'components/Modals/ModalWrap';
import { makeStyles } from '@material-ui/core/styles';
import useRouter from 'utils/useRouter';
import { useDispatch } from 'react-redux';
import { Typography, Grid } from '@material-ui/core';
import { LEAD_TYPES, CATALOGUE_TYPE_PACK, DEPARTMENTS } from 'config'
import moment from 'moment'
import CustomTextInput from 'components/CustomTextInput';
import CustomTable from 'components/CustomTable'

const useStyles = makeStyles(theme => ({
    root: {},
    label: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(1)
    },
    item: {
        margin: theme.spacing(1),
        marginLeft: 0
    },
    description: {
        margin: theme.spacing(0, 1)
    }
}));
const FormDetails = ({ formatMessage, data, open, handleClose, companies }) => {
    const router = useRouter();
    const dispatch = useDispatch();
    const classes = useStyles();
    const { source, userId, type, recipientInfo, clientId } = data;

    const packData = source?.data ? JSON.parse(source.data) : null;

    const traineeTable = trainee.map(key => ({
        name: key,
        label: formatMessage({ id: "leads.form." + key.toLowerCase() }),
        options: {
            display: true,
            sort: false,
            customBodyRender: (_value, tableMeta) => {
                let value = _value;
                if (key == "birth") value = moment(value).format("DD/MM/YYYY");
                if (key == "region") {
                    const depart = DEPARTMENTS.find(el => el.id == value)
                    value = depart ? depart.label : value
                }
                if (key == "gender") {
                    value = value ? formatMessage({ id: "leads.form.gender." + value }) : ""
                }
                return value
            }
        }
    }));

    const recieptTable = recipients.map(key => ({
        name: key,
        label: formatMessage({ id: "leads.form." + key }),
        options: {
            display: true,
            sort: false,
        }
    }));
    const company = companies.find(el => el.id == clientId);

    return (
        <ModalWrap
            title={"Lead"}
            open={open}
            closeModal={handleClose}
        >
            {
                type == LEAD_TYPES[0] ? //hubspot
                    <div>
                        <Typography className={classes.label}><strong>{source.title || ""}</strong></Typography>
                        <Typography className={classes.item}>{source.description}</Typography>
                    </div> : (
                        type == "hubspotform" ?
                            <div>
                                <Typography className={classes.label}><strong>{data.name || ""}</strong></Typography>
                                <Typography className={classes.label}><strong>Form Fields :</strong></Typography>
                                {
                                    data.formFieldGroups.map((group, index) => {
                                        return <div key={index}>
                                            <Grid container>
                                                {
                                                    group.fields.map((field, idx) => {
                                                        return <Grid key={idx} item xs={4}>
                                                            <div className={classes.label} style={{ fontWeight: "bold", fontSize: 14 }} dangerouslySetInnerHTML={{ __html: field.label }} />
                                                            <Typography className={classes.item}>{field.fieldType.toUpperCase()}</Typography>
                                                        </Grid>
                                                    })
                                                }
                                            </Grid>
                                        </div>
                                    })
                                }
                            </div> :
                            <div>
                                <CustomTextInput
                                    fullWidth
                                    variant="outlined"
                                    label={'PRODUIT :'}
                                    value={source.title || ""}
                                    disabled
                                    multiline
                                />
                                {
                                    type == LEAD_TYPES[4] ? //product request
                                        <>
                                            <Grid container spacing={2} >
                                                <Grid item xs={6}>
                                                    <CustomTextInput
                                                        fullWidth
                                                        variant="outlined"
                                                        label={`${formatMessage({ id: "leads.form.firstname" })} :`}
                                                        value={userId ? data.owner_firstName : source.firstName}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextInput
                                                        fullWidth
                                                        variant="outlined"
                                                        label={`${formatMessage({ id: "leads.form.lastname" })} :`}
                                                        value={userId ? data.owner_lastName : source.lastName}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextInput
                                                        fullWidth
                                                        variant="outlined"
                                                        label={`${formatMessage({ id: "leads.form.email" })} :`}
                                                        value={userId ? data.owner_email : source.email}
                                                        disabled
                                                    />
                                                </Grid>
                                                <Grid item xs={6}>
                                                    <CustomTextInput
                                                        fullWidth
                                                        variant="outlined"
                                                        label={`${formatMessage({ id: "leads.form.phone" })} :`}
                                                        value={userId ? data.owner_phone : source.phone}
                                                        disabled
                                                    />
                                                </Grid>
                                            </Grid>
                                            <CustomTextInput
                                                fullWidth
                                                variant="outlined"
                                                label={'Comment :'}
                                                value={source.description || ""}
                                                disabled
                                                multiline
                                            />
                                        </> :
                                        <>

                                            {
                                                source.productType == CATALOGUE_TYPE_PACK ?
                                                    (
                                                        packData &&
                                                        <>
                                                            <br />
                                                            <br />
                                                            <Grid container spacing={2}>
                                                                <Grid item xs={6}>
                                                                    <Typography className={classes.label}><strong>PRODUITS</strong></Typography>
                                                                    {
                                                                        packData.products.map(el => <Typography key={el.id} className={classes.item}>- {el.title}</Typography>)
                                                                    }
                                                                </Grid>
                                                                <Grid item xs={6}>
                                                                    {
                                                                        packData.options.length > 0 &&
                                                                        <>
                                                                            <Typography className={classes.label}><strong>OPTIONS (valable uniquement en plus du pack)</strong></Typography>
                                                                            {
                                                                                packData.options.map(el => <Typography key={el.id} className={classes.item}>- {el.title}</Typography>)
                                                                            }
                                                                        </>
                                                                    }
                                                                </Grid>
                                                            </Grid>

                                                            <CustomTextInput
                                                                fullWidth
                                                                variant="outlined"
                                                                label={'PRIX H.T. :'}
                                                                value={`${packData.packPrice}€ HT`}
                                                                disabled
                                                            />
                                                            <br />
                                                            <br />
                                                            <Typography variant="h5" className={classes.label}><strong>SIGNATAIRE</strong></Typography>
                                                            <div style={{ marginLeft: 16 }}>
                                                                <Grid container spacing={2} >
                                                                    {
                                                                        signatorInfo.map(el => {
                                                                            return <Grid key={el} item xs={4}>
                                                                                <CustomTextInput
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    label={`${formatMessage({ id: "leads.form." + el })} :`}
                                                                                    value={packData.signatorInfo[el]}
                                                                                    disabled
                                                                                />
                                                                            </Grid>
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </div>
                                                            <br />
                                                            <Typography variant="h5" className={classes.label}><strong>FACTURATION</strong></Typography>
                                                            <div style={{ marginLeft: 16 }}>
                                                                <Grid container spacing={2} >
                                                                    {
                                                                        billingInfo.map(el => {
                                                                            return <Grid key={el} item xs={4}>
                                                                                <CustomTextInput
                                                                                    fullWidth
                                                                                    variant="outlined"
                                                                                    label={`${formatMessage({ id: "leads.form." + el })} :`}
                                                                                    value={packData.billingInfo[el]}
                                                                                    disabled
                                                                                />
                                                                            </Grid>
                                                                        })
                                                                    }
                                                                </Grid>
                                                            </div>
                                                            <br />
                                                            <Typography variant="h5" className={classes.label}><strong>DESTINATAIRE(S) DU RAPPORT</strong></Typography>
                                                            <CustomTable
                                                                columns={recieptTable}
                                                                data={packData.recipients}
                                                                options={{
                                                                    pagination: false,
                                                                    print: false,
                                                                    download: false,
                                                                    selectableRows: 'none',
                                                                    search: true,
                                                                    filter: true,
                                                                    viewColumns: false,
                                                                    elevation: 0,
                                                                    filter: false,
                                                                    search: false
                                                                }}
                                                            />
                                                            <br />
                                                            <br />
                                                        </>
                                                    ) :
                                                    <>
                                                        {source.intra ?
                                                            <>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'Nombre de stagiaire :'}
                                                                            value={source.traineeCount}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'Date souhaité :'}
                                                                            value={moment(source.orderDate).format("DD/MM/YYYY")}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                            </> :
                                                            <>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={4}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'PRODUIT CODE :'}
                                                                            value={packData?.ref_id}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'PRIX :'}
                                                                            value={`${parseFloat(source.price) * source.quantity}€`}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={4}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'CHOISIR UNE DATE :'}
                                                                            value={packData?.training_date ? moment(packData?.training_date).format("DD/MM/YYYY") : ""}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Grid container spacing={2}>
                                                                    <Grid item xs={6}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'RÉGION :'}
                                                                            value={packData?.training_location_title}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                    <Grid item xs={6}>
                                                                        <CustomTextInput
                                                                            fullWidth
                                                                            variant="outlined"
                                                                            label={'MERCI DE CHOISIR UN CENTRE DE FORMATION :'}
                                                                            value={packData?.training_center_name}
                                                                            disabled
                                                                        />
                                                                    </Grid>
                                                                </Grid>
                                                                <Typography className={classes.label}><strong>Veuillez compléter les informations ci-dessous pour chaque participant :</strong></Typography>
                                                                <CustomTable
                                                                    columns={traineeTable}
                                                                    data={(source.trainees || []).filter(el => el).map(el => ({ ...el, establishment: el.company }))}
                                                                    options={{
                                                                        pagination: false,
                                                                        print: false,
                                                                        download: false,
                                                                        selectableRows: 'none',
                                                                        search: true,
                                                                        filter: true,
                                                                        viewColumns: false,
                                                                        elevation: 0,
                                                                        filter: false,
                                                                        search: false
                                                                    }}
                                                                />
                                                            </>
                                                        }
                                                        {
                                                            recipientInfo &&
                                                            <>
                                                                {
                                                                    recipientInfo.signatorInfo && recipientInfo.signatorInfo.email ? <>
                                                                        <br />
                                                                        <Typography variant="h5" className={classes.label}><strong>SIGNATAIRE ({recipientInfo.signatorInfo.isParticular ? "Particulier" : "Entreprise"})</strong></Typography>
                                                                        <div style={{ marginLeft: 16, marginBottom: 16 }}>
                                                                            <Grid container spacing={2}>
                                                                                {
                                                                                    !recipientInfo.signatorInfo.isParticular &&
                                                                                    <>
                                                                                        <Grid item xs={4}>
                                                                                            <CustomTextInput
                                                                                                fullWidth
                                                                                                variant="outlined"
                                                                                                label={'Dénomination sociale :'}
                                                                                                value={recipientInfo.signatorInfo.company}
                                                                                                disabled
                                                                                            />
                                                                                        </Grid>
                                                                                        <Grid item xs={4}>
                                                                                            <CustomTextInput
                                                                                                fullWidth
                                                                                                variant="outlined"
                                                                                                label={'Siret :'}
                                                                                                value={recipientInfo.signatorInfo.siret}
                                                                                                disabled
                                                                                            />
                                                                                        </Grid>
                                                                                    </>
                                                                                }
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Adresse postale :'}
                                                                                        value={recipientInfo.signatorInfo.address}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Code postal :'}
                                                                                        value={recipientInfo.signatorInfo.zipcode}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Ville :'}
                                                                                        value={recipientInfo.signatorInfo.city}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Pays :'}
                                                                                        value={recipientInfo.signatorInfo.country}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Nom signataire :'}
                                                                                        value={recipientInfo.signatorInfo.lastname}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Prénom signataire :'}
                                                                                        value={recipientInfo.signatorInfo.firstname}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Fonction :'}
                                                                                        value={recipientInfo.signatorInfo.function}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Téléphone :'}
                                                                                        value={recipientInfo.signatorInfo.phone}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                                <Grid item xs={4}>
                                                                                    <CustomTextInput
                                                                                        fullWidth
                                                                                        variant="outlined"
                                                                                        label={'Email :'}
                                                                                        value={recipientInfo.signatorInfo.email}
                                                                                        disabled
                                                                                    />
                                                                                </Grid>
                                                                            </Grid>
                                                                        </div>
                                                                    </> :
                                                                        <>
                                                                            {
                                                                                company &&
                                                                                <>
                                                                                    <br />
                                                                                    <Typography variant="h5" className={classes.label}><strong>Enterprise</strong></Typography>
                                                                                    <div style={{ marginLeft: 16, marginBottom: 16 }}>
                                                                                        <Grid container spacing={2}>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Dénomination sociale :'}
                                                                                                    value={company.company}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Siret :'}
                                                                                                    value={company.siret}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Adresse postale :'}
                                                                                                    value={company.address}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Code postal :'}
                                                                                                    value={company.zipCode}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Ville :'}
                                                                                                    value={company.city}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Pays :'}
                                                                                                    value={company.country}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Nom signataire :'}
                                                                                                    value={company.lastName}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Prénom signataire :'}
                                                                                                    value={company.firstName}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Téléphone :'}
                                                                                                    value={company.phone}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                            <Grid item xs={4}>
                                                                                                <CustomTextInput
                                                                                                    fullWidth
                                                                                                    variant="outlined"
                                                                                                    label={'Email :'}
                                                                                                    value={company.email}
                                                                                                    disabled
                                                                                                />
                                                                                            </Grid>
                                                                                        </Grid>
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                            {
                                                                                userId &&
                                                                                <>
                                                                                    <br />
                                                                                    <Typography variant="h5" className={classes.label}><strong>{packData?.company && packData.company._id ? "Destinataires" : "Destinataires Offre"}</strong></Typography>
                                                                                    <div style={{ marginLeft: 16, marginBottom: 16 }}>
                                                                                        {
                                                                                            packData?.company && packData.company._id ?
                                                                                                <>
                                                                                                    <Grid container spacing={2}>
                                                                                                        {
                                                                                                            ['name',
                                                                                                                'siret',
                                                                                                                'email',
                                                                                                                'address',
                                                                                                                'zipCode',
                                                                                                                'city',
                                                                                                                'pays'].map(key => {
                                                                                                                    return <Grid key={key} item xs={4}>
                                                                                                                        <CustomTextInput
                                                                                                                            fullWidth
                                                                                                                            variant="outlined"
                                                                                                                            label={formatMessage({ id: 'home.clients.establishments.' + key }) + ' :'}
                                                                                                                            value={packData.company[key]}
                                                                                                                            disabled
                                                                                                                        />
                                                                                                                    </Grid>
                                                                                                                })
                                                                                                        }
                                                                                                    </Grid>
                                                                                                </> :
                                                                                                <>
                                                                                                    {
                                                                                                        company ?
                                                                                                            <Grid container spacing={2}>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Adresse postale :'}
                                                                                                                        value={company.address}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Code postal :'}
                                                                                                                        value={company.zipCode}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Ville :'}
                                                                                                                        value={company.city}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Pays :'}
                                                                                                                        value={company.country}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Nom signataire :'}
                                                                                                                        value={company.lastName}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Prénom signataire :'}
                                                                                                                        value={company.firstName}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Téléphone :'}
                                                                                                                        value={company.phone}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                                <Grid item xs={4}>
                                                                                                                    <CustomTextInput
                                                                                                                        fullWidth
                                                                                                                        variant="outlined"
                                                                                                                        label={'Email :'}
                                                                                                                        value={company.email}
                                                                                                                        disabled
                                                                                                                    />
                                                                                                                </Grid>
                                                                                                            </Grid> :
                                                                                                            <Typography variant="h6" style={{ textAlign: "center" }}>The user who created this order is deleted.</Typography>
                                                                                                    }
                                                                                                </>
                                                                                        }
                                                                                    </div>
                                                                                </>
                                                                            }
                                                                        </>
                                                                }
                                                                {
                                                                    recipientInfo.billingInfo &&
                                                                    <>
                                                                        <br />
                                                                        <Typography variant="h5" className={classes.label}><strong>FACTURATION DE LA FORMATION ({formatMessage({ id: "leads.form.invoicetype." + (recipientInfo.billingInfo.invoiceType || "billing") })})</strong> </Typography>
                                                                        {
                                                                            !recipientInfo.signatorInfo.isParticular &&
                                                                            <div style={{ marginLeft: 16, marginBottom: 16 }}>
                                                                                <Grid container spacing={2} >
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={recipientInfo.billingInfo.isOPCO ? "Nom de l’organisme :" : "Dénomination sociale :"}
                                                                                            value={recipientInfo.billingInfo.company}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={recipientInfo.billingInfo.isOPCO ? "Numéro d’adhérent auprès de l’OPCO :" : "Siret :"}
                                                                                            value={recipientInfo.billingInfo.siret}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={"Adresse postale :"}
                                                                                            value={recipientInfo.billingInfo.address}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={"Code postal :"}
                                                                                            value={recipientInfo.billingInfo.zipcode}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={"Ville :"}
                                                                                            value={recipientInfo.billingInfo.city}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                    <Grid item xs={4}>
                                                                                        <CustomTextInput
                                                                                            fullWidth
                                                                                            variant="outlined"
                                                                                            label={"Pays :"}
                                                                                            value={recipientInfo.billingInfo.country}
                                                                                            disabled
                                                                                        />
                                                                                    </Grid>
                                                                                </Grid>
                                                                            </div>
                                                                        }
                                                                    </>
                                                                }
                                                            </>
                                                        }
                                                    </>
                                            }
                                        </>
                                }
                            </div>)
            }
        </ModalWrap>
    );
};

FormDetails.propTypes = {};

export default FormDetails;

const signatorInfo = [
    "company", "siret", "address", "zipcode", "city", "lastname", "firstname", "function", "phone", "email"
]
const billingInfo = [
    "company", "siret", "address", "zipcode", "city", "phone", "email"
]
const recipients = [
    "lastname", "firstname", "function", "phone", "email"
]

const trainee = [
    "gender", "lastName", "firstName", "birth", "establishment" //"region"
]